<template>
  <van-dialog
    v-model="show"
    :title="title"
    :show-cancel-button="false"
    :showConfirmButton="false"
    class="custom-dialog"
  >
    <div class="dialog-content">
      <div class="desc">
        <slot></slot>
      </div>
    </div>
    <div class="footer-btn">
      <img src="@/assets/img/trace-close.png" alt="" @click="close" class="icon-close" />
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "custom-dialog",
  props: {
    show:{
      type:Boolean,
    },
    title: {
      type: String,
    }
  },
  data() {
    return {
    };
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" >
  @import "../assets/less/customDialog";
</style>
