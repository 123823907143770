<template>
  <div class="custom-footer">
    <p>AI技术由中科睿鉴提供</p>
  </div>
</template>

<script>
export default {
  name: "custom-footer",
  props: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="less">
.custom-footer {
  text-align: center;
  > p {
    color: #AEBFD7;
    font-size: 28px;
  }
}
</style>

