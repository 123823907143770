<template>
  <custom-popup :show="showPicker" title="运行条件" class="condition-modal" @close="closeModal">
    <h4 class="sub-title">请选择要运行的硬件平台</h4>
      <ul class="checkbox-list">
        <li class="item" v-for="item in platformList" :key="item.id" @click="chooseCheck(item)" :class="(formInline.valueList || []).includes(item.id) ? 'active' : ''">
          <div class="option">{{item.name}}（{{item.gpu}}）</div>
        </li>
      </ul>
      <h4 class="sub-title">请选择推理次数<img @click="openDialog" src="@/assets/img/deep-question.png" class="icon-question"/></h4>
      <div class="picker-list">
        <van-picker
          show-toolbar
          :columns="unspeedColumns"
          @change="onChange($event, 'num')"
          cancel-button-text=' '
          confirm-button-text=' '
          title="未加速版推理次数"
          :item-height="0.88 * fontSize +'px'"
          visible-item-count="4"
          defaultIndex="5"
          ref="unspeedPicker"
        />
        <van-picker
          show-toolbar
          :columns="speedColumns"
          @change="onChange($event, 'speedNum')"
          cancel-button-text=' '
          confirm-button-text=' '
          title="加速版推理次数"
          :item-height="0.88 * fontSize +'px'"
          visible-item-count="4"
          defaultIndex="5"
          ref="speedPicker"
        />
      </div>
      <template #footer>
        <van-button type="info" class="submit-btn" :disabled="!(formInline.valueList && formInline.valueList.length)" @click="onConfirm">确认</van-button>
      </template>
  </custom-popup>
</template>

<script>
import CustomPopup from "@/components/CustomPopup.vue";

let UNSPEED_COLUMNS = [];
for(let i = 10; i <= 200; i = i + 10) {
  UNSPEED_COLUMNS.push(i)
}

let SPEED_COLUMNS = [];
for(let i = 100; i <= 1000; i = i + 100) {
  SPEED_COLUMNS.push(i)
}

export default {
  name: "condition-modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    formData: {},
    platformList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    CustomPopup,
  },
  data(){
    return {
      showPicker: false,
      unspeedColumns: [1].concat(UNSPEED_COLUMNS),
      speedColumns: [1].concat(SPEED_COLUMNS),
      formInline: {},
      num: [100],

      dialogDesc: {desc: '<div>①本平台通过推理次数来模拟得出真实环境下模型的平均推理耗时。例如，若选择加速版推理次数为500次，则得出的平均推理耗时表示加速版模型在推理500次情况下的平均推理耗时；</br></br>②为了更加直观地体现加速版模型的平均推理优势，在选择推理次数时，建议选择加速版推理次数>=300次。</div>'},
      dialogTitle: '关于推理次数',
      fontSize: 0,
    };
  },
  watch: {
    show(oldValue, newValue) {
      if (oldValue != newValue) {
        this.showPicker = true;
        this.formInline = {...this.formData};
        // 若滑动太快还没选中直接先了确认，再次打开的时候需要显示最新值
        setTimeout(() => {
          this.$refs.unspeedPicker && this.$refs.unspeedPicker.setValues([this.formData.num])
          this.$refs.speedPicker && this.$refs.speedPicker.setValues([this.formData.speedNum])
        }, 1)
        // 获取根元素html的fontSize
        this.fontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize)
      }
    },
  },
  mounted() {
  },
  methods: {
    closeModal() {
      this.showPicker = false;
    },

    // 选择硬件平台
    chooseCheck(item) {
      let {valueList} = this.formInline;
      if (valueList.includes(item.id)) {
        valueList = valueList.filter(id => id != item.id);
      } else {
        valueList.push(item.id);
      }
      this.formInline.valueList = valueList.slice();
    },

    onChange(e, key){
      let valueList = e.getValues();
      if (valueList) {
        this.formInline = {
          ...this.formInline,
          [key]: valueList[0]
        }
      }
    },

    onConfirm() {
      this.$emit('confirm', this.formInline);
      this.closeModal();
    },

    openDialog() {
      this.$emit('openDialog', this.dialogTitle, this.dialogDesc)
    }

  }
};
</script>

<style lang="less">
  .condition-modal {
    .modal-content {
      .sub-title {
        .icon-question {
          width: 30px;
          height: 30px;
          margin-left: 8px;
        }
      }
      .checkbox-list {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 32px;
        .item {
          width: 50%;
          display: inline-block;
          padding: 0 15px;
          margin-bottom: 32px;
          .option {
            height: 96px;
            padding: 24px 70px 24px 24px;
            font-size: 30px;
            display: flex;
            align-items: center;
            background-image: linear-gradient(270deg, #B7CDDE 0%, #89A8BF 100%);
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
          }
          &.active {
            .option {
              background-image: linear-gradient(270deg, #009DFF 0%, #006CFF 100%);
              &::after {
                content: '';
                width: 47px;
                height: 36px;
                background-image: url(../../assets/img/icon-true.png);
                background-size: cover;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                position: absolute;
                top: 24px;
                right: 12px;
              }
            }
          }
        }
      }
      .picker-list {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        .van-picker {
          flex: 1;
          margin: 0 15px;
          color: #00F1FF;
          .van-picker__title {
            max-width: 80%;
          }
          .van-picker__toolbar {
            margin-bottom: 25px;
          }
          .van-picker__mask {
            background: transparent;
          }
          .van-picker-column__item {
            color: #fff;
            padding: 10px;
            &.van-picker-column__item--selected {
              color: #00F1FF;
              font-weight: bold;
              font-size: 36px;
            }
          }
          .van-picker__frame {
            background-color: rgba(0,123,255,.25);
            &::after {
              border: none;
            }
          }
        }
      }
    }
  }
</style>
