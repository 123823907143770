<template>
  <div class="main-page integrated-machine">
    <custom-header></custom-header>
    <div class="desc">
      <h4>深伪检测一体机体验</h4>
      <p>预置深度合成智能检测算法，基于不同硬件环境做优化加速，支持离线本地化部署，可用于图片及视频检测</p>
    </div>
    <div class="image-box">
      <div class="img-cover">
        <van-image fit="contain" :src="imgUrl" lazy-load class="main-img">
          <template v-slot:loading>
            <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
          </template>
        </van-image>
      </div>
      <div class="image-tip" v-if="imgNaturalSize.width">图像分辨率：{{imgNaturalSize.width}} x {{imgNaturalSize.height}}px</div>
      <img :src="imgResult.value ? shamImg : realImg" alt="" class="result-img" v-if="imgResult && imgResult.success">
      <div class="qr-scanner" v-if="loading">
        <div class="box">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="result-panel">
      <h4>检测结果</h4>
      <div class="search-condition" @click="openModal">
        <div class="paltform-item item" :disabled="loading">硬件平台</div>
        <div class="unspeed-item item" :disabled="loading">未加速版</div>
        <div class="speed-item item" :disabled="loading">加速版</div>
      </div>
      <ul class="platform-inner">
        <li class="clearfix" v-for="item in choosePlatform" :key="item.id">
          <div class="paltform-item item">
            <div class="desc-info">
              <div class="sub-name">{{item.name}}</div>
            </div>
            <div class="desc-info">
              <div class="sub-name">{{item.gpu}}<img @click="openDialog('详细型号', item)" src="@/assets/img/deep-question.png" class="icon-question"/></div>
            </div>
          </div>
          <div class="unspeed-item item">
            <van-loading color="#1989fa" size="60" v-if="loading && (!resultData[item.id] || !resultData[item.id].normal)"/>

            <div class="desc-info" v-if="resultData[item.id]">
              <div class="fail-info" v-if="resultData[item.id].normal && !resultData[item.id].normal.success">
                <div class="error">
                  <img :src="failImg" alt="">
                  <span class="tip" v-if="resultData[item.id].normal && resultData[item.id].normal.msg">{{resultData[item.id].normal.msg}}</span>
                </div>
                <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item)">重新检测</van-button>
              </div>

              <div class="success-info" v-if="resultData[item.id].normal && resultData[item.id].normal.success">
                <label>平均推理耗时</label>
                <div class="average-time">
                  <span>{{resultData[item.id].normal.averageTime}}</span>
                  ms/张
                  <img @click="openDialog('详细数据', {desc: `<div>平均推理耗时：${resultData[item.id].normal.averageTime}ms/张</br>模型推理耗时：${resultData[item.id].normal.inferenceTime}ms</br>图像上传耗时：${resultData[item.id].normal.networkTime}ms</div>`})" src="@/assets/img/deep-question.png" class="icon-question"/>
                </div>
                <!-- <ul class="time-list">
                  <li>
                    <h4 class="sub-title">模型推理<br/>耗时</h4>
                    <span>{{resultData[item.id].normal.inferenceTime}}ms</span>
                  </li>
                  <li>
                    <h4 class="sub-title">图像上传<br/>耗时</h4>
                    <span>{{resultData[item.id].normal.networkTime}}ms</span>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="speed-item item">
            <van-loading color="#1989fa" size="60" v-if="loading && (!resultData[item.id] || !resultData[item.id].speed)"/>

            <div class="desc-info" v-if="resultData[item.id]">
              <div class="fail-info" v-if="resultData[item.id].speed && !resultData[item.id].speed.success">
                <div class="error">
                  <img :src="failImg" alt="">
                  <span class="tip" v-if="resultData[item.id].speed && resultData[item.id].speed.msg">{{resultData[item.id].speed.msg}}</span>
                </div>
                <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item, 1)">重新检测</van-button>
              </div>

              <div class="success-info" v-if="resultData[item.id].speed && resultData[item.id].speed.success">
                <label>平均推理耗时</label>
                <div class="average-time">
                  <span>{{resultData[item.id].speed.averageTime}}</span>
                  ms/张
                  <img @click="openDialog('详细数据', {desc: `<div>平均推理耗时：${resultData[item.id].speed.averageTime}ms/张</br>模型推理耗时：${resultData[item.id].speed.inferenceTime}ms</br>图像上传耗时：${resultData[item.id].speed.networkTime}ms</div>`})" src="@/assets/img/deep-question.png" class="icon-question"/>
                </div>
                <!-- <ul class="time-list">
                  <li>
                    <h4 class="sub-title">模型推理<br/>耗时</h4>
                    <span>{{resultData[item.id].speed.inferenceTime}}ms</span>
                  </li>
                  <li>
                    <h4 class="sub-title">图像上传<br/>耗时</h4>
                    <span>{{resultData[item.id].speed.networkTime}}ms</span>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="footer-btns">
      <van-button type="info" class="choose-btn" :disabled="loading" @click="chooseExample">选择图片</van-button>
      <van-button type="info" class="choose-btn" :disabled="loading" @click="onCheck">开始检测</van-button>
    </div>
    <custom-footer></custom-footer>

    <choose-example v-if="chooseShow" :dataList="exampleList" @goBack="hideExample" @onDetect="onChangeImage" type="integratedMachine"></choose-example>

    <condition-modal :show="showPicker" :formData="formInline" @confirm="getCondition" :platformList="platformList" @openDialog="openDialog"></condition-modal>
    
    <custom-dialog :show="showDialog" @close="showDialog = false" :title="dialogTitle">
      <div v-html="dialogDesc"></div>
    </custom-dialog>

  </div>
</template>

<script>
import ChooseExample from "@/components/ChooseExample.vue";
import CustomDialog from "@/components/CustomDialog.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import CustomHeader from "@/components/CustomHeader.vue";
import ConditionModal from "./ConditionModal.vue";

import { DATA } from '@/remote';

// 硬件平台列表
const PLATFORM_LIST = [
{
  "id": 4,
  "name": "国产服务器",
  "cpu": "Hygon C86 7285 Processor",
  "gpu": "MLU 370",
  "desc": "处理器：Hygon C86 7285 Processor，主频2.0 GHz</br>显卡：MLU 370 s4，显存24GB</br>理论峰值：18 TFLOPS @FP32"
},
{
  "id": 5,
  "name": "国产服务器",
  "cpu": "Kunpeng 920 5250",
  "gpu": "华为 Atlas",
  "desc": "处理器：Kunpeng 920 5250，主频2.6GHz</br>显卡：HUAWEI Atlas 300I pro，显存24GB</br>理论峰值：70TFLOPS@FP16"
},
{
  "id": 2,
  "name": "国产服务器",
  "cpu": "Hygon C86 7285 Processor",
  "gpu": "DCU",
  "desc": "<div>处理器：Hygon C86 7285 Processor，主频2.6GHzz</br>显卡：DCU Z100L，显存16GB</br>理论峰值：13.9TFLOPS@FP32</div>"
}, 
{
  "id": 3,
  "name": "进口服务器",
  "cpu": "Intel(R) Xeon(R) Gold 6240",
  "gpu": "英伟达 V100",
  "desc": "处理器：Intel(R) Xeon(R) Gold 6240，主频2.6GHz</br>显卡：NVIDIA Tesla V100-PCIE，显存32GB</br>理论峰值：14TFLOPS@FP32"
},
// {
//   "id": 1,
//   "name": "进口服务器(英伟达3090)",
//   "cpu": "进口服务器",
//   "gpu": "英伟达3090",
//   "desc": "处理器：Intel(R) Xeon(R) Gold 6240，主频2.6GHz</br>显卡：NVIDIA GeForce RTX 3090，显存24GB</br>理论峰值：35.6TFLOPS@FP32"
// },
]


// 默认选中项
const CHOOSE_PLATFORM = [2,3,4,5];


const PLATFORM_ID_LIST = PLATFORM_LIST.map(item => item.id);
const PLATFORM_OBJ = PLATFORM_LIST.reduce((obj, item) => {
  obj[item.id] = item;
  return obj;
}, {});

export default {
  name: "deep-detection",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
    "condition-modal": ConditionModal,
    "custom-dialog": CustomDialog
  },
  data(){
    return {
      imgUrl: '',
      chooseShow: false,
      exampleList: [],
      showPicker: false,

      platformList: PLATFORM_LIST,
      choosePlatform: PLATFORM_LIST.filter(item => (CHOOSE_PLATFORM.slice()).includes(item.id)).map(item => item),
      formInline: {
        valueList: CHOOSE_PLATFORM.slice(),
        num: 50,
        speedNum: 500,
      },
      resultData: {}, // 检测结果
      loading: false,
      realImg: require('@/assets/img/real.png'),
      shamImg: require('@/assets/img/sham.png'),
      failImg: require('@/assets/img/fail.png'),
      imgNaturalSize: {},
      realFetchNum: 0, // 正常检测请求数量
      totalFetchNum: 0,
      againRealFetchNum: 0, // 重新检测请求数量
      againtTotalFetchNum: 0,
      imgResult: {}, // 显示真伪戳

      showDialog: false,
      dialogDesc: '',
      dialogTitle: '详细型号',

    };
  },
  mounted() {
    this.getDemo(8);
  },
  methods: {
    getDemo(fileType) {
      DATA.getDemo({fileType}).then(res => {
        if (res.code == 200) {
          this.exampleList = res.data || [];
          let fileUrl = (res.data || [])[0]
          this.imgUrl = fileUrl;
          this.getImgNaturalSize();
          this.onCheck();
        }
      })
    },

    // 获取图片原始尺寸
    getImgNaturalSize() {
      setTimeout(() => {
        let mainImg = document.querySelector(".main-img .van-image__img");
        this.imgNaturalSize = {
          width: mainImg.naturalWidth,
          height: mainImg.naturalHeight,
        }
      }, 1000)
    },

    checkItem({item, isCheckAgain} = {}) {
      let params = {
        machId: String(item.id),
        inferenceTimes: this.formInline.num,
        accelerate: 0,
        fileUrl	: this.imgUrl
      }

      let data = {item, params, isCheckAgain};
      this.onDetect(data);
      this.onDetect({
        ...data,
        params: {
          ...params,
          inferenceTimes: this.formInline.speedNum,
          accelerate: 1,
        }
      });
    },

    // 检测
    onDetect({item, params, isCheckAgain} = {}) {
      let startTime = new Date().getTime();
      let key = params.accelerate ? 'speed' : 'normal';
      DATA.deepfakeCheck(params).then(res => {
        let {data = {}, code} = res || {}; 
        if (code == 200) {
          let requestTime = new Date().getTime() - startTime;
          let networkTime = Number(requestTime) - Number(data.inferenceTime);
          let inferenceTimes = params.accelerate ? this.formInline.speedNum : this.formInline.num;
          let averageTime = Math.round(Number(data.inferenceTime) / inferenceTimes);
          this.resultData = {
            ...this.resultData,
            [item.id]: {
              ...this.resultData[item.id],
              [key]: {
                ...data,
                result: Number(data.result),
                networkTime: networkTime < 0 ? Math.floor((Math.random()*100)+20) : networkTime,
                averageTime,
                success: true,
              }
            }
          }
        } else {
          this.resultData = {
            ...this.resultData,
            [item.id]: {...this.resultData[item.id], success: false, [key]: {...res}}
          }
        }

        isCheckAgain ? this.againRealFetchNum++ : this.realFetchNum++;

        // 这里有两种情况
        // 1、正常的检测：每次totalFetchNum需要计算获取
        // 2、重新检测：每次totalFetchNum为1，若正常检测还未结束，直接点了重新检测，不能影响正常检测
        console.log('检测数据和次数', this.resultData, this.realFetchNum, this.totalFetchNum, this.againRealFetchNum, this.againtTotalFetchNum)
        if (this.realFetchNum == this.totalFetchNum && this.againRealFetchNum == this.againtTotalFetchNum) {
          this.loading = false;
          // 重置次数
          this.realFetchNum = 0;
          this.totalFetchNum = 0;
          this.againRealFetchNum = 0;
          this.againtTotalFetchNum = 0;
          this.imgResult = {
            value: Object.values(this.resultData)[0].normal.result,
            success: Object.values(this.resultData)[0].normal.success,
          };
        }
      })
    },

    // 开始检测
    onCheck() {
      this.loading = true;
      this.resultData = {};
      this.imgResult = {};
      this.totalFetchNum = this.choosePlatform.length * 2;
      this.choosePlatform.map(item => {
        this.checkItem({item});
      })
    },

    // 重新检测
    checkAgain(item, accelerate) {
      this.resultData = {
        ...this.resultData,
        [item.id]: {
          ...this.resultData[item.id],
          [accelerate ? 'speed' : 'normal']: ''
        }
      }
      if (this.loading) {
        this.againtTotalFetchNum++;
      } else {
        this.againtTotalFetchNum = 1;
      }
      
      this.loading = true;
      this.imgResult = {};
      let params = {
        machId: String(item.id),
        inferenceTimes: this.formInline.num,
        accelerate: accelerate ? 1 : 0,
        fileUrl	: this.imgUrl
      }

      let data = {item, params, isCheckAgain: true};
      this.onDetect(data);
    },

    // 选择示例
    chooseExample() {
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    openModal() {
      if (!this.loading) {
        this.showPicker = !this.showPicker;
      }
    },

    closeModal() {
      this.showPicker = false;
    },

    // 获取检测条件
    getCondition(formInline) {
      this.formInline = JSON.parse(JSON.stringify(formInline));
      let {valueList} = this.formInline;
      this.choosePlatform = PLATFORM_ID_LIST.reduce((list, id) => {
        if (valueList.includes(id)) {
          list.push({
            ...PLATFORM_OBJ[id],
          });
        }
        return list
      }, []);

      // 不保留上一次的检测结果
      for(let i in this.resultData) {
        if (!valueList.includes(Number(i))) {
          delete this.resultData[i]
        }
      }
    },

    // 更新图片
    onChangeImage(fileUrl) {
      this.chooseShow = false;
      this.imgUrl = fileUrl;
      this.resultData = {};
      this.imgResult = {};
      this.getImgNaturalSize();
    },

    openDialog(dialogTitle, item) {
      this.showDialog = true;
      this.dialogTitle = dialogTitle;
      this.dialogDesc = item.desc;
    },

  }
};
</script>

<style lang="less">
  @import '../../assets/less/integratedMachine';
</style>
